import { useState, useCallback, useRef } from "react";
import "./HotSpot.scss";
import hotspot from "../../data/hotspot.json";
import customerinfo from "../../data/customerinfo";
import Map, {
  Marker,
  Popup,
  NavigationControl,
  FullscreenControl,
  ScaleControl,
  GeolocateControl,
  Layer,
  Source,
  MapRef,
} from "react-map-gl";
import Pin from "../../assets/pin";
import "mapbox-gl/dist/mapbox-gl.css";
import { heatmapLayer } from "../../helpers/heatmapLayer";
import {
  sortVendorsByDistance,
  vendorBranchData,
} from "../../helpers/branchVendor";
import { escapeString } from "../../helpers/escapeString";
import { downloadCsv } from "../../helpers/downloadCsv";
import { Header } from "../../components/Header";
import { ActionButtons } from "../../components/ActionButtons";
import { CardButton, TextInput } from "@fsg/gui-bits";

const hotspotData = hotspot.filter(
  (item: any) => item["Latitude"] && item["Longitude"]
);

function HotSpot({ publicView = false }) {
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [customerData] = useState<any>(hotspotData);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const map = useRef<MapRef>(null);

  document.title = `FSG ${publicView ? "(Public)" : ""} - HotSpot Coverage Map`;

  const exportData = useCallback(() => {
    let csvContent =
      "Store Number,Store Name,Inspector,City,State,Zip,V1,V1 Distance,V2,V2 Distance,V3,V3 Distance,V4,V4 Distance,V5,V5 Distance\n";
    customerData.forEach(function (row: any) {
      const vendors = sortVendorsByDistance(row["Latitude"], row["Longitude"]);
      const rowArray = [
        row["StoreNumber"],
        row["StoreName"],
        row["INSPECTOR"],
        row["City"],
        row["State"],
        row["Zip"],
        vendors[0]["Name"],
        vendors[0]["distance"].toFixed(1),
        vendors[1]["Name"],
        vendors[1]["distance"].toFixed(1),
        vendors[2]["Name"],
        vendors[2]["distance"].toFixed(1),
        vendors[3]["Name"],
        vendors[3]["distance"].toFixed(1),
        vendors[4]["Name"],
        vendors[4]["distance"].toFixed(1),
      ];
      csvContent += rowArray.map(escapeString).join(",");
      csvContent += "\n";
    });

    downloadCsv(csvContent, "hotspot");
  }, [customerData]);

  return (
    <div className="hotspot-page">
      <Header customer="HotSpot" />
      <div className="dashboard-content">
        <ActionButtons
          customerGuid={customerinfo.hotspot.guid}
          publicView={publicView}
          exportData={exportData}
        />

        <div className="map-container">
          <div className="site-list">
            <div className="site-search">
              <TextInput
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search for a site"
              />
            </div>
            <div className="site-list-list">
              {customerData
                .filter((x: any) => {
                  if (searchTerm === "") {
                    return true;
                  }
                  return (
                    x["StoreName"]
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase()) ||
                    x["Address"]
                      .toLowerCase()
                      .includes(searchTerm.toLowerCase())
                  );
                })
                .map((item: any) => (
                  <CardButton
                    key={item["StoreNumber"]}
                    className="site-listing"
                    onClick={() => {
                      map.current?.flyTo({
                        center: [item["Longitude"], item["Latitude"]],
                        zoom: 10,
                        duration: 1000,
                      });
                    }}
                  >
                    <h4>{item["StoreName"]}</h4>
                    <address>
                      <div>{item["Address"]}</div>
                      <div>
                        {item["City"]}, {item["State"]} {item["Zip"]}
                      </div>
                    </address>
                  </CardButton>
                ))}
            </div>
          </div>

          <Map
            ref={map}
            mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
            initialViewState={{
              longitude: -95.7,
              latitude: 37.09,
              zoom: 3,
            }}
            style={{ width: "100%", height: "80vh", marginTop: "1rem" }}
            mapStyle="mapbox://styles/mapbox/streets-v9"
            onClick={() => {
              setSelectedLocation(null);
            }}
            onDrag={() => {
              setSelectedLocation(null);
            }}
          >
            <GeolocateControl position="top-left" />
            <FullscreenControl position="top-left" />
            <NavigationControl position="top-left" />
            <ScaleControl />

            {customerData.map((location: any) => (
              <Marker
                key={location["StoreNumber"]}
                latitude={location["Latitude"]}
                longitude={location["Longitude"]}
                anchor="bottom"
                onClick={(e) => {
                  e.originalEvent.stopPropagation();
                  setSelectedLocation(location);
                }}
              >
                <Pin fill="rgb(251, 83, 27)" />
              </Marker>
            ))}

            {selectedLocation && (
              <Popup
                anchor="top"
                latitude={selectedLocation["Latitude"]}
                longitude={selectedLocation["Longitude"]}
                onClose={() => setSelectedLocation(null)}
                style={{ maxWidth: "350px !important" }}
              >
                <div>
                  <h3>{selectedLocation["StoreName"]}</h3>
                  <p>Store #: {selectedLocation["StoreNumber"]}</p>
                  <p>Inspector: {selectedLocation["INSPECTOR"]}</p>
                  <p>
                    {selectedLocation["Address"]}
                    <br />
                    {selectedLocation["City"]}, {selectedLocation["State"]}{" "}
                    {selectedLocation["Zip"]}
                  </p>
                </div>
              </Popup>
            )}

            <Source
              type="geojson"
              data={{
                type: "FeatureCollection",
                features: vendorBranchData.map((item) => ({
                  type: "Feature",
                  properties: {},
                  geometry: {
                    type: "Point",
                    coordinates: [item["Longitude"], item["Latitude"]],
                  },
                })),
              }}
            >
              <Layer {...heatmapLayer}></Layer>
            </Source>
          </Map>
        </div>
      </div>
    </div>
  );
}

export default HotSpot;
